import { Box, Typography, Container, Link } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

function App() {

  const [direction, setDirection] = useState('in');
  const circleRef = useRef(null);
  const boxSize = Math.min(window.innerWidth, window.innerHeight) / 3;


  useEffect(() => {

    let startTime;
    let growing = true;

    const minRadius = boxSize * (1 / 3);


    const animate = (timestamp) => {
      if (!startTime) {
        startTime = timestamp;
      }

      const elapsed = timestamp - startTime;

      let radius = minRadius;

      // if the radius is the minimum size, we need to grow until it reaches the maximum size, then we shrink


      if (growing) {
        // Grow radius for 4 seconds

        radius = minRadius + (elapsed / 10000 * boxSize);


        if (radius >= boxSize * 2 / 3) {
          // Reached max size, switch to shrinking
          growing = false;
          setDirection("out");
          startTime = timestamp;
        }
      }
      else {
        // Shrink radius for 4 seconds
        radius = Math.max(boxSize * 2 / 3 - (elapsed / 10000 * boxSize), boxSize / 3);

        if (radius <= boxSize / 3) {
          // Reached min size, switch to growing
          growing = true;
          setDirection('in');
          startTime = timestamp;
        }
      }

      circleRef.current.setAttribute("r", radius);

      requestAnimationFrame(animate);
    }


    requestAnimationFrame(animate);

  }, []);

  return (
    <div style={{ backgroundColor: "#28536b", width: "100vw", height: "100vh", minHeight: '100vh', flexDirection: 'column', position: 'relative', display: 'flex' }}>
      <Box style={{ alignContent: 'center' }}>
        <div
          style={{
            borderRadius: '10px',
            backgroundColor: "#b68d40",
            width: boxSize,
            height: boxSize,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            flexGrow: 1
          }}
        >
          <svg
            viewBox={`-50 -50 ${boxSize + 100} ${boxSize + 100}`}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <circle
              ref={circleRef}
              cx={(boxSize / 2)}
              cy={(boxSize / 2)}
              fill="#c2e1e7"
            />
          </svg>

        </div>

        <Typography variant='h4' style={{ color: '#c2e1e7', position: "absolute", top: "60%", left: '50%', transform: "translate(-50%, -50%)", }}> {direction} </Typography>
      </Box>
      <Box sx={{ minWidth: '100%', marginTop: 'auto' }}>
        <Container maxWidth="lg">
          <Typography sx={{ color: '#c2e1e7' }} variant="body1" align="center">
            © {new Date().getFullYear()} <a style={{ color: '#c2e1e7' }} href='https://tinypaul.io'>tinypaul.io</a>
          </Typography>
        </Container>
      </Box>
    </div >
  );
}

export default App;